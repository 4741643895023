<template>
  <div class="app flex-row align-items-center guest">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" lg="6">
          <b-card-group>
            <b-card no-body class="p-3">
              <b-card-body>
                <form @submit.prevent="validateBeforeSubmit">
                  <img class="mx-auto d-block mb-4 navbar-brand-full" src="img/brand/invoicelogo.png" height="95" alt="RTNEST Inc. Logo">
                  <h1>Tech Support</h1>
                  <p class="text-muted">Select a problem</p>
                  <flash-message autoHide variant="success"></flash-message>

                  <b-input-group class="mb-2">
                    <b-input-group-prepend style="z-index: 99;"><b-input-group-text><i class="icon-settings"></i></b-input-group-text></b-input-group-prepend>
                    <select class="select-account form-control" name="problem" v-validate="'required'" v-model="problem">
                      <option value="" disabled selected>Select From Dropdown</option>
                      <option for v-for="option in problems"  v-bind:value="option.problem">{{option.problem}}</option>
                    </select>
                    <i class="dropdown-icon fa fa-chevron-down position-absolute"></i>
                  </b-input-group>
                  <i v-show="errors.has('problem')" class="fa fa-exclamation-triangle"></i> <span v-show="errors.has('problem')" class="mb-3 help is-danger">The type is required.</span>

                  <b-input-group class="mb-2">
                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="text" class="form-control" name="email" v-model="email" v-validate="'required|email'" placeholder="Email" autocomplete="username email" />
                  </b-input-group>
                  <i v-show="errors.has('email')" class="fa fa-exclamation-triangle mb-3"></i> <span v-show="errors.has('email')" class="mb-3 help is-danger">{{ errors.first('email') }}</span>

                  <b-input-group class="mb-2">
                    <b-input-group-prepend><b-input-group-text><i class="icon-phone"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="text" class="form-control" name="phone" v-model="phone" v-validate="'required'" placeholder="Phone Number" />
                  </b-input-group>
                  <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle mb-3"></i> <span v-show="errors.has('phone')" class="mb-3 help is-danger">{{ errors.first('phone') }}</span>

                  <b-input-group class="mb-2">
                    <b-input-group-prepend><b-input-group-text><i class="fal fa-comment"></i></b-input-group-text></b-input-group-prepend>
                    <textarea class="form-control" name="message" v-model="message" v-validate="'required'" placeholder="Message" />
                  </b-input-group>
                  <i v-show="errors.has('message')" class="fa fa-exclamation-triangle mb-3"></i> <span v-show="errors.has('message')" class="mb-3 help is-danger">{{ errors.first('message') }}</span>

                  <input type="hidden" name="robot" v-model="robot" v-validate="'required'" />

                  <i v-show="error" class="fa fa-exclamation-triangle"></i><span v-show="error" class="help is-danger">Message not sent. Please contact us by phone.</span>

                  <b-input-group class="cap">
                    <vue-recaptcha ref="reCaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" class="mt-2" sitekey="6LfAwYcUAAAAACZe3pwLB7otQzENyRr_UlZshjx6">
                    </vue-recaptcha>
                  </b-input-group>
                  <i v-show="errors.has('robot')" class="fa fa-exclamation-triangle mt-2 mb-2"></i> <span v-show="errors.has('robot')" class="mb-3 help is-danger">The reCaptcha is required.</span>

                  <b-row class="mt-3">
                    <b-col cols="12" class="col-md-5">
                      <b-button variant="primary" type="submit" class="w-100 px-5">Send</b-button>
                    </b-col>
                  </b-row>
                </form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Vue from 'vue'
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Login',
  components: {
    vSelect,
    VueRecaptcha
  },
  data: function () {
    return {
      email: '',
      problem: '',
      phone: '',
      message: '',
      robot: '',
      error: '',
      problems: [
        {
          problem: "General Support",
        },
        {
          problem: "Technical Support",
        },
        {
          problem: "Bug Report",
        },
        {
          problem: "Emergency",
        },
        {
          problem: "Feature Request",
        },
        {
          problem: "Other",
        },
      ]
    }
  },
  methods: {
    onCaptchaExpired: function () {
      this.robot = 'false';
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.robot = 'true';
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
            let data = JSON.stringify({
              problem: this.problem,
              email: this.email,
              phone: this.phone,
              message: this.message
            })
            this.$axios.post("/" + this.account + '/signin', data, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic Og=='
              }
            })
            .then(response => {
              this.flash({ message: 'Message Sent Successful', variant: 'success' });
              window.reload();
            })
            .catch(error => {
              this.error = true;
              this.$refs.reCaptcha.reset();
            })
            return;
          }
      });
    },
  }
}
</script>

<style lang="scss">
  .select-account {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 10px;
  }
  .fa-exclamation-triangle {
    margin-right: 7px;
  }
  .dropdown-icon {
    right: 15px;
    top: 10px;
    z-index: 99;
  }
</style>
